import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import Addresses from "./addresses";
import Suggestions from "./suggestions";

function App() {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [requestPending, setRequestPending] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    setError("");

    // Retrieve environment variables
    const storedUsername = process.env.REACT_APP_USERNAME;
    const storedPassword = process.env.REACT_APP_PASSWORD;

    console.log(storedUsername);

    // Check if credentials match
    if (
      formData.username === storedUsername &&
      formData.password === storedPassword
    ) {
      setLoggedIn(true);
      setError("");
      Cookies.set("isLoggedIn", "true"); // store login status
      handleCloseModal();
    } else {
      setError("Invalid credentials");
    }
    setRequestPending(false);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    Cookies.remove("isLoggedIn");
    handleShowModal();
  };

  useEffect(() => {
    const isLoggedInCookie = Cookies.get("isLoggedIn");
    if (isLoggedInCookie) {
      setLoggedIn(true);
    } else {
      handleShowModal();
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="layout layout-nav-side">
        <Sidebar />
        <div className="main-container">
          <Navbar loggedIn={loggedIn} onLogout={handleLogout} />
          {loggedIn && (
            <Routes>
              <Route path="/" element={<Suggestions />} />
              <Route path="/suggestions" element={<Suggestions />} />
              <Route path="/addresses" element={<Addresses />} />
            </Routes>
          )}
        </div>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={handleCloseModal}
        className="modal-grow"
      >
        <form onSubmit={formSubmit}>
          <Modal.Header className="bg-white border-bottom">
            <h6>Login required!</h6>
          </Modal.Header>
          <Modal.Body>
            {error && (
              <div className="alert alert-secondary" role="alert">
                {error}
              </div>
            )}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">person</i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">lock</i>
                </span>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-white border-top">
            <button
              className="mt-auto ml-auto btn btn-primary d-block shadow-sm"
              disabled={requestPending}
            >
              {requestPending ? "Please wait..." : "Submit"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </BrowserRouter>
  );
}

export default App;
