import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { ref, set, remove, onValue } from "firebase/database";
import database from "./firebase";

const Addresses = () => {
  const [formData, setFormData] = useState({ address: "" });
  const [addresses, setAddresses] = useState({}); // Use an object to store addresses

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!formData.address) return; // Prevent adding empty addresses

    set(ref(database, `SUGGESTIONS/addresses/${Date.now()}`), {
      address: formData.address,
    })
      .then(() => {
        setFormData({ address: "" }); // Clear input field
      })
      .catch((error) => {
        console.error("Error adding address: ", error);
      });
  };

  const deleteAddress = (id) => {
    remove(ref(database, `SUGGESTIONS/addresses/${id}`))
      .then(() => {
        console.log("Address deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting address: ", error);
      });
  };

  useEffect(() => {
    const addressesRef = ref(database, "SUGGESTIONS/addresses");
    const unsubscribe = onValue(addressesRef, (snapshot) => {
      const addrList = snapshot.val() || {};
      setAddresses(addrList); // Set addresses state
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []);

  return (
    <>
      <form
        className="card shadow-none d-flex flex-row align-items-center"
        onSubmit={submitForm}
      >
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons icon-light">email</i>
            </span>
          </div>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="form-control border-0"
            placeholder="Type here to add address to forward suggestions"
            aria-label="Add address"
          />
        </div>

        <button type="submit" className="ml-auto btn btn-round mr-1 shadow">
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </form>

      <ul className="list-group box-shadow rounded-0 mt-4">
        {Object.entries(addresses).map(([id, addr]) => (
          <li className="list-group-item d-flex" key={id}>
            <span>{addr.address}</span>
            <button
              className="btn btn-round ml-auto"
              onClick={() => deleteAddress(id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Addresses;
