import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import truncateString from "./utils/truncateString";

const Navbar = ({ loggedIn, onLogout }) => {
  const location = useLocation();
  const [paths, setPath] = useState([]);

  const handleLogoutClick = () => {
    onLogout();
  };

  useEffect(() => {
    setPath(location.pathname.split("/").filter((item) => item !== ""));
  }, [location]);

  function cFLetter(string) {
    if (string.length === 0) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="breadcrumb-bar navbar bg-white sticky-top">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {paths.map((path, index) => (
            <li
              className={`breadcrumb-item ${
                index === paths.length - 1 && "active"
              }`}
              key={index}
            >
              {index != paths.length - 1 ? (
                <Link to={index > 1 ? `${paths[index - 1]}/${path}` : path}>
                  {cFLetter(path)}
                </Link>
              ) : (
                truncateString(cFLetter(path), 20)
              )}
            </li>
          ))}
        </ol>
      </nav>

      <div className="dropdown">
        <button
          className="btn btn-round"
          role="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="material-icons">settings</i>
        </button>
        <div className="dropdown-menu dropdown-menu-right ">
          <button
            className="dropdown-item"
            role="button"
            onClick={handleLogoutClick}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
