import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons"; // Include faTrash for delete functionality
import { Button } from "react-bootstrap";
import TableStyle1 from "./TableStyle";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { onValue, orderByKey, query, ref, remove } from "firebase/database"; // Import remove function
import database from "./firebase"; // Ensure to import your firebase configuration
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import exportToExcel from "./xlsx";

const Suggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleSelection, setToggleSelection] = useState(false);

  // Handle selected row
  const handleSelectedRow = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  // Function to handle deletion of selected rows
  const deleteSuggestion = () => {
    // Logic to delete selected rows from Firebase
    selectedRows.map((row) => {
      const suggestionRef = ref(database, `SUGGESTIONS/feedback/${row.id}`);
      setToggleSelection(!toggleSelection);
      remove(suggestionRef);
    });
  };

  const columns = [
    {
      name: "Date",
      cell: (row) => <span>{moment(Number(row.id)).format("YYYY-MM-DD")}</span>,
      style: { width: "150px" },
    },
    {
      name: "Fullnames",
      cell: (row) => <span className="row-title">{row.name}</span>,
    },
    {
      name: "Email",
      cell: (row) => <span>{row.email}</span>,
    },
    {
      name: "Phone",
      cell: (row) => <span>{row.phone}</span>,
    },
    {
      name: "Suggestion",
      cell: (row) => <span>{row.suggestion}</span>,
      style: { width: "300px" },
    },
  ];

  // Fetch suggestions from Firebase on component mount
  useEffect(() => {
    const suggestionsRef = ref(database, "SUGGESTIONS/feedback");
    const orderedQuery = query(suggestionsRef, orderByKey());

    const unsubscribe = onValue(orderedQuery, (snapshot) => {
      const data = snapshot.val();
      const suggestionsList = data
        ? Object.entries(data).map(([key, value]) => ({ id: key, ...value }))
        : [];
      setSuggestions(suggestionsList.reverse()); // Reverse to get descending order
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    // Filter suggestions based on the search query across all fields
    if (value.length > 0) {
      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.name.toLowerCase().includes(value.toLowerCase()) ||
          suggestion.email.toLowerCase().includes(value.toLowerCase()) ||
          suggestion.phone.includes(value) || // Searching by phone number
          suggestion.suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResult(filteredSuggestions);
    } else {
      setSearchResult([]);
    }
  };

  const contextAction = (
    <button className="btn" type="button" onClick={() => deleteSuggestion()}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );

  return (
    <>
      <div className="card shadow-none d-flex flex-row align-items-center">
        <div className="input-group border-0 mr-2">
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0">
              <i className="material-icons">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search"
            onChange={handleSearch}
            aria-label="Search"
          />
        </div>

        <button
          type="button"
          className="mr-1 btn btn-round shadow"
          onClick={() => {
            // Transform suggestions data before exporting
            const formattedSuggestions = suggestions.map((suggestion) => ({
              date: moment(Number(suggestion.id)).format("YYYY-MM-DD"), // Rename id to date and format it
              fullName: suggestion.name,
              email: suggestion.email,
              phone: suggestion.phone,
              suggestion: suggestion.suggestion,
            }));

            exportToExcel(
              formattedSuggestions,
              "suggestions_list", // Changed to a more file-friendly name
              "GWEL SUGGESTIONS LIST"
            );
          }}
        >
          <span className="material-icons">download</span>
        </button>
      </div>

      <div className="card shadow-none mt-4 box-shadow">
        <DataTable
          title={"Suggestions feedback"}
          contextActions={contextAction}
          customStyles={TableStyle1}
          columns={columns}
          data={searchQuery.length > 0 ? searchResult : suggestions}
          pagination
          paginationPerPage={5}
          selectableRows
          onSelectedRowsChange={handleSelectedRow}
          clearSelectedRows={toggleSelection} // Clear selection when all are deleted
        />
      </div>
    </>
  );
};

export default Suggestions;
